import React, {useEffect, useState} from "react";
import {
    Button,
    Container,
    Form,
    Grid, GridRow,
    Header,
    Icon,
    Image,
    Input,
    Item,
    Label,
    Modal,
    Segment,
    Step,
    Table
} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import {useSelector} from "react-redux";
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import SelectShoptable from "../components/CreateIntervention/SelectShopTable";
import {toastSuccess} from "../reducers/Global/global.actions";
import {NumericFormat} from "react-number-format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CopyToClipboard from "../components/CopyToClipboard/CopyToClipboard";
import InitialQuoteTable from "../components/DetailIntervention/InitialQuoteTable";
import test from "./test.svg";

const DetailInterventionPage = () => {

    const {id} = useParams();
    const {t, i18n} = useTranslation();

    const ImageWithFallback = ({src, style}) => {
        const [imgError, setImgError] = useState(false);

        const handleError = () => {
            setImgError(true);
        };

        return imgError ? (<FontAwesomeIcon icon="fa-regular fa-screwdriver-wrench" style={{fontSize: "25px", padding:"5px"}}/>) : (<Image
            spaced='right'
            src={src}
            onError={handleError}
            style={style}
        />);
    };

    const [intervention, setIntervention] = useState(null);
    const broker = useSelector(state => state.user.broker);
    const [openDetailAddress, setOpenDetailAddress] = useState(false);
    const [selectShopModal, setSelectShopModal] = useState(false);
    const [showInitialQuote, setShowInitialQuote] = useState(false);
    const [franchisePayedDate, setFranchisePayedDate] = useState(null);


    const getIntervention = () => {
        if (id) {
            axiosInstance.get("/followup/" + id)
                .then((response) => {
                    setIntervention(response.data)
                })
        }
    }

    useEffect(() => {
        getIntervention();
    }, []);

    useEffect(() => {
        if(intervention?.franchise) {
            axiosInstance.get("/followup/" + id+"/franchise")
                .then((response) => {
                    setFranchisePayedDate(response.data)
                })
        }
    }, [intervention]);

    const addressHasElements = () => {
        return intervention.customerAddress || intervention.customerAddressComplement || intervention.customerZipCode || intervention.customerCity || intervention.customerCountry;
    }

    let hasInitialQuote = intervention && intervention.initialRepairs && intervention.initialRepairs.length > 0;
    let totalBillingAmount = intervention && intervention.repairs.map((repair) => (repair.amountTTC)).reduce((x, y) => x + y, 0) + intervention.discountAmountTTC
    let irrep = intervention && intervention.closingDate && intervention.status === "21";

    function dowloadInvoice() {
        axiosInstance.get("/followup/" + intervention.interventionId + "/billing", {responseType: 'blob'})
            .then(response => {
                const blob = new Blob([response.data], {type: 'application/pdf'});
                const url = window.URL.createObjectURL(blob);
                const newTab = window.open(url, '_blank');
                if (newTab) {
                    newTab.addEventListener('beforeunload', () => {
                        window.URL.revokeObjectURL(url);
                    });
                } else {
                    alert("Le navigateur a bloqué l'ouverture de la nouvelle fenêtre. Veuillez autoriser les pop-ups.");
                }
            });
    }

    const barreStyle = {
        width: '200px',
        height: '3px',
        backgroundColor: '#00BFB2',
        margin: '0 auto'
    };

    return (intervention &&
        <>
            <h1>{t('page.repairs.file-detail')}
                <span
                    style={{
                        width: '7px',
                        height: '7px',
                        display: 'inline-block',
                        backgroundColor: '#C0C0C0',
                        borderRadius: '50%',
                        marginLeft: '10px',
                        marginRight: '10px',
                        verticalAlign: 'middle'
                    }}
                ></span>
                <span style={{color: '#00BFB2', fontSize: "smaller", fontWeight: "lighter"}}>
                    <CopyToClipboard text={intervention.numDossier}/>
                </span>
            </h1>
            <Container>

                <Step.Group size='small' fluid>
                    <Step>
                        <Icon name='folder open'/>
                        <Step.Content>
                            <Step.Title>{t('page.repairs.file-creation')}</Step.Title>
                            <Step.Description><Moment format="ddd DD MMM YYYY HH:mm"
                                                      locale="fr">{intervention.creationDate}</Moment></Step.Description>
                            {intervention.createdBy && <Step.Description>{t('page.repairs.by')} <b>{intervention.createdBy}</b></Step.Description>}
                        </Step.Content>
                    </Step>

                    <Step active={!intervention.receptionDate}>
                        <Icon name={intervention.receptionDate ? 'inbox' : 'hourglass half'}/>
                        <Step.Content>
                            <Step.Title>{intervention.receptionDate ? t('page.repairs.product-received') : t('page.repairs.product-wait')}</Step.Title>
                            <Step.Description>{intervention.receptionDate && <Moment format="ddd DD MMM YYYY HH:mm"
                                                                                     locale="fr">{intervention.receptionDate}</Moment>}</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={!intervention.closingDate} disabled={!intervention.receptionDate}>
                        <Icon name='cog'/>
                        <Step.Content>
                            <Step.Title>{t('page.repairs.file-treatment')}</Step.Title>
                            <Step.Description></Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={intervention.closingDate} disabled={!intervention.closingDate}>
                        <Icon name='folder'/>
                        <Step.Content>
                            {irrep && <Step.Title style={{color: 'red'}}>{t('page.repairs.file-irreparable')}</Step.Title>}
                            {!irrep && <Step.Title>{t('page.repairs.file-closed')}</Step.Title>}
                            <Step.Description>{intervention.closingDate && <Moment format="ddd DD MMM YYYY HH:mm"
                                                                                   locale="fr">{intervention.closingDate}</Moment>}</Step.Description>
                        </Step.Content>
                    </Step>
                </Step.Group>

                <Segment style={{padding: '35px'}}>
                    <Grid divided='vertically' stackable>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <div style={{display: 'flex', justifyContent: 'start'}}>
                                    <div style={{marginRight: '10px'}}>
                                        <FontAwesomeIcon icon='fa-regular fa-mobile' color={"teal"} size='5x'/>
                                    </div>
                                    <div>
                                        <Item>
                                            <Item.Content>
                                                <Item.Header><b>{intervention.brandName}</b>&nbsp;{intervention.modelName}
                                                </Item.Header>
                                                <Item.Meta>
                                                    {intervention.imei && <CopyToClipboard text={intervention.imei}/>}
                                                </Item.Meta>
                                                <Item.Extra>
                                                    {intervention.productColor &&
                                                        <Label
                                                            size={"mini"}>{t('colors.' + intervention.productColor)}</Label>}
                                                    {intervention.guaranteeCode && <Label size={"mini"}
                                                                                          content={t('guarantee.' + intervention.guaranteeCode)}/>}
                                                </Item.Extra>
                                            </Item.Content>
                                        </Item>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column textAlign={"center"}>
                                <Header as='h3'>
                                    <Header.Content>
                                        {t('page.partner.infos')}
                                    </Header.Content>
                                </Header>
                                <span ><CopyToClipboard
                                    text={intervention.externalReference ? intervention.externalReference : intervention.numDossier}/></span>
                                {intervention.threshold ? <div style={{marginTop: "6px"}}>Plafond : <b><NumericFormat
                                    value={intervention.threshold}
                                    displayType={"text"}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=","
                                    suffix=" €"
                                /> </b></div> : null}
                                {intervention.franchise ? <div  style={{marginTop: (intervention.threshold ? "0px" : "6px")}}>{t('page.intervention.deductible')} : <b><NumericFormat
                                    value={intervention.franchise}
                                    displayType={"text"}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=","
                                    suffix=" €"
                                />
                                    {franchisePayedDate ? <span style={{color: 'green'}}>&nbsp;{t('page.repairs.payed')}</span> :
                                        <span style={{color: 'red'}}>&nbsp;{t('page.repairs.payed')}</span>}
                                </b></div> : null}
                            </Grid.Column>
                            <Grid.Column textAlign={"right"}>
                                <Header as='h3' style={{marginBottom: "10px"}}>
                                    <Header.Content>
                                        <FontAwesomeIcon icon="fa-solid fa-store"/> &nbsp; {t('page.intervention.repair-shop')}
                                    </Header.Content>

                                </Header>
                                {intervention.shopName ?
                                    <span>{intervention.shopName} ({intervention.shopCode}) </span> :
                                    <Button
                                        onClick={() => setSelectShopModal(true)} basic color={"white"}
                                        size={"mini"}>{t('page.repairs.select-shop')}</Button>}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

                {!intervention.closingDate && hasInitialQuote && <>
                    <Segment style={{padding: '35px'}}>
                        <Header as='h3' textAlign={"center"}>{t('page.repairs.initial-request')}</Header>
                        <div style={barreStyle}></div>
                        <InitialQuoteTable intervention={intervention}/>
                    </Segment>
                </>}

                {intervention.closingDate && <>

                    <Segment style={{padding: '35px'}} textAlign={"center"}>
                        <Header as='h3'>{t('page.repairs.details')}</Header>
                        <div style={barreStyle}></div>

                        {(broker.contractType === "INSURANCE" || intervention.guaranteeCode === 'COV_BROKER') && intervention.repairs?.length > 0 && <>
                            <Grid textAlign={"left"} style={{marginTop: '10px'}}>
                                <Grid.Row style={{marginLeft: '20px'}}>
                                    <Grid.Column width={9}>
                                        <div style={{
                                            border: '1px solid #00bfb2',
                                            borderRadius: '8px',
                                            padding: '15px 20px 15px 20px',
                                            height: '100%',
                                            minWidth: '250px'
                                        }}>
                                            <Header as='h4' style={{boxSizing: 'border-box'}}>
                                                <FontAwesomeIcon icon="fa-regular fa-screwdriver-wrench"
                                                                 style={{marginRight: '5px'}}/>
                                                {t('page.repairs.repairs')}
                                            </Header>
                                            <Grid style={{paddingBottom: "5px"}}>
                                                {intervention.repairs?.map((repair, index) => {
                                                    return (<Grid.Column key={index} style={{
                                                        padding: '5px',
                                                        marginTop: "5px",
                                                        marginLeft: "10px",
                                                        flexGrow: "1",
                                                        width: "auto"
                                                    }}>
                                                        <div style={{
                                                            margin: '0px !important',
                                                            backgroundColor: '#E8E8E8',
                                                            borderRadius: '10px',
                                                            padding: '5px 10px',
                                                            minWidth: '200px',
                                                            minHeight: '50px',
                                                            display: "inline-block"
                                                        }}>
                                                            <Grid columns={2} stackable>
                                                                <Grid.Row>
                                                                    <Grid.Column verticalAlign='middle' style={{
                                                                        width: "auto", display: "inline-block"
                                                                    }}>
                                                                        <ImageWithFallback
                                                                            spaced='right'
                                                                            src={"https://drpciyuuf9kdh.cloudfront.net/save/quote/quote-repair/partner/Icon_" + repair.category + ".svg"}
                                                                            style={{
                                                                                margin: 'auto',
                                                                                width: 'auto',
                                                                                height: '35px'
                                                                            }}
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column verticalAlign='middle' style={{
                                                                        padding: "0px 10px 0px 0px",
                                                                        width:"auto",
                                                                        flex:"1"
                                                                    }}>
                                                                        <Grid.Row>
                                                                            <p style={{marginBottom: "0px", whiteSpace: "nowrap"}}><b>{i18n.exists('category.' + repair.category) ? t('category.' + repair.category) : repair.category}</b>
                                                                            &nbsp;&nbsp;{repair.reference}</p>
                                                                        </Grid.Row>
                                                                        <Grid.Row>
                                                                            <p style={{marginRight: "10px", width:"auto", whiteSpace:"pre-wrap", wordBreak:"normal"}}><b>{repair.designation}</b></p>
                                                                        </Grid.Row>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </div>
                                                    </Grid.Column>)
                                                })}
                                            </Grid>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                        <div style={{
                                            border: '1px solid #00bfb2',
                                            borderRadius: '8px',
                                            padding: '15px 20px 15px 20px',
                                            height: '100%',
                                            minWidth: '250px'
                                        }}>
                                            <Header as='h4'>
                                                <FontAwesomeIcon icon="fa-regular fa-comment"
                                                                 style={{marginRight: '5px'}}/>
                                                {t('page.repairs.technician-comment')}
                                            </Header>
                                            <p style={{
                                                color: 'grey', textAlign: 'center'
                                            }}>"{intervention.technicianComment}"&nbsp;&nbsp;</p>
                                            <p style={{
                                                color: "black", fontStyle: 'italic', textAlign: 'right'
                                            }}>{intervention.technicianName}</p>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </>
                        }


                        {(broker.contractType !== "INSURANCE" && intervention.guaranteeCode !== 'COV_BROKER') && intervention.repairs?.length > 0 && <><Table
                            basic={"very"} compact={true}
                            style={{padding: '5px 20px 5px 20px'}}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.HeaderCell>{t('page.repairs.repairs')}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={"right"}>{t('page.pricing.price-ttc')}</Table.HeaderCell>
                                </Table.Row>

                                {intervention.repairs?.map((repair, index) => {
                                    return (
                                        <Table.Row style={{padding: '0px !important'}}>
                                            <Table.Cell style={{margin: '0px !important'}}>
                                                {i18n.exists('category.' + repair.category) ? t('category.' + repair.category) : repair.category}
                                            </Table.Cell>
                                            <Table.Cell textAlign={"right"}>
                                                <NumericFormat
                                                    value={repair.amountTTC}
                                                    displayType={'text'}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    decimalSeparator=','
                                                    suffix=' €'
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                                }

                                {intervention.discountAmountTTC && intervention.discountAmountTTC !== 0 && <>
                                    <Table.Row style={{padding: '0px !important'}}>
                                        <Table.Cell style={{margin: '0px !important'}}>
                                            <Label size={"tiny"} basic color={"teal"}>{t('page.summary.partner-discount')}</Label>
                                        </Table.Cell>
                                        <Table.Cell textAlign={"right"}>
                                            <NumericFormat
                                                value={intervention.discountAmountTTC}
                                                displayType={'text'}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                decimalSeparator=','
                                                suffix=' €'
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                </>}
                                <Table.Row style={{padding: '0px !important'}}>
                                    <Table.Cell style={{margin: '0px !important'}}>
                                        <b>{t('page.summary.total')}</b>
                                    </Table.Cell>
                                    <Table.Cell textAlign={"right"}>
                                        <b><NumericFormat
                                            value={totalBillingAmount}
                                            displayType={'text'}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            decimalSeparator=','
                                            suffix=' €'
                                        /></b>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                            {broker.billingValidationType !== 'PSM_BILL' && (
                                <Button primary size={"small"} onClick={() => dowloadInvoice()}>
                                    {t('page.repairs.download-bill')}
                                </Button>
                            )}
                        </>}

                        {intervention.repairs?.length === 0 && <>
                            <br/><b>{intervention.status === "21" ? t('page.repairs.irreparable') : t('page.repairs.no-repair')}</b><br/>
                            {intervention.status === "21" ? t('motif.' + intervention.motif) : null}<br/>
                        </>}


                        {hasInitialQuote && <>
                            <Header as='h4' style={{
                                        textAlign: 'left',
                                        paddingLeft: '20px',
                                        cursor: 'pointer'
                                    }}
                                onClick={() => setShowInitialQuote(!showInitialQuote)}>
                                {t('page.repairs.initial-request')} <Icon name={showInitialQuote ? "caret down" : "caret up"}/>
                        </Header>
                            {showInitialQuote && <InitialQuoteTable intervention={intervention}/>}</>}
                    </Segment>
                </>}

                <Segment style={{padding: '35px'}}>
                    <Header as='h3'>{t('page.repairs.customer-infos')}</Header>
                    <Grid style={{padding: '10px'}}>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <Header dividing={true} as='h4'>
                                    <Header.Content>
                                        {t('generic.civilite')}
                                    </Header.Content>
                                </Header>
                                <Form>
                                    <Form.Field inline>
                                        <label> {t('generic.lastname')} :</label>
                                        <Input fluid value={intervention.customerLastName} readOnly/>
                                    </Form.Field>
                                    <Form.Field inline>
                                        <label> {t('generic.firstname')} :</label>
                                        <Input fluid value={intervention.customerFirstName} readOnly/>
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <Header dividing={true} as='h4'>
                                    <Header.Content>
                                        {t('generic.address')}
                                    </Header.Content>
                                </Header>
                                {!openDetailAddress && <Segment>
                                    {addressHasElements() && <>
                                        {intervention.customerAddress}<br/>
                                        {intervention.customerAddressComplement}
                                        {intervention.customerAddressComplement && <br/>}
                                        {intervention.customerZipCode}, {intervention.customerCity}<br/>
                                        {intervention.customerCountry}<br/>
                                    </>}
                                    {!addressHasElements() && <>
                                        {t('generic.no-address')}<br/>
                                    </>}
                                    <div className="detail-link">
                                        <a onClick={() => setOpenDetailAddress(true)}>{t('page.repairs.detail-link')}</a>
                                    </div>
                                </Segment>}
                                {openDetailAddress && <Segment>
                                    <Form.Field inline>
                                        <label>{t('generic.address')} :</label>
                                        <Input fluid value={intervention.customerAddress} readOnly/>
                                    </Form.Field>
                                    <Form.Field inline>
                                        <label>{t('generic.address-comp')} :</label>
                                        <Input fluid value={intervention.customerAddressComplement} readOnly/>
                                    </Form.Field>
                                    <Form.Field inline>
                                        <label>{t('generic.postal-code')} :</label>
                                        <Input fluid value={intervention.customerZipCode} readOnly/>
                                    </Form.Field>
                                    <Form.Field inline>
                                        <label>{t('generic.city')} :</label>
                                        <Input fluid value={intervention.customerCity} readOnly/>
                                    </Form.Field>
                                    <Form.Field inline>
                                        <label>{t('generic.country')} :</label>
                                        <Input fluid value={intervention.customerCountry} readOnly/>
                                    </Form.Field>

                                    <div className="detail-link">
                                        <a onClick={() => setOpenDetailAddress(false)}>{t('page.repairs.close-detail-link')}</a>
                                    </div>
                                </Segment>}
                            </Grid.Column>
                            <Grid.Column>
                                <Header dividing={true} as='h4'>
                                    <Header.Content>
                                        {t('page.partner.contact')}
                                    </Header.Content>
                                </Header>
                                <Form>
                                    <Form.Field inline>
                                        <label>{t('generic.email')} :</label>
                                        <Input fluid value={intervention.customerEmail} readOnly/>
                                    </Form.Field>
                                    <Form.Field inline>
                                        <label>{t('generic.phone-number')} :</label>
                                        <Input fluid value={intervention.customerPhone} readOnly/>
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

                <Modal open={selectShopModal} onClose={() => setSelectShopModal(false)}>
                    <Modal.Header>{t('page.intervention.store-choice')}</Modal.Header>
                    <Modal.Content>
                        <SelectShoptable
                            interventionId={intervention.interventionId}
                            zipCode={intervention.customerZipCode}
                            onSuccess={(shop) => {
                                setSelectShopModal(false);
                                toastSuccess(t('page.intervention.store-choice-success'));
                                setIntervention({...intervention, shopName: shop.name, shopCode: shop.code})
                            }}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setSelectShopModal(false)}>
                            {t('generic.cancel')}
                        </Button>
                    </Modal.Actions>
                </Modal>


            </Container>
        </>
    );
};

export default DetailInterventionPage;

import {Form} from 'semantic-ui-react'
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateCustomer} from "../../reducers/Create/create.actions";
import {useForm} from "react-hook-form";
import PageHeader from "../PageHeader/PageHeader";
import axiosInstance from "../../utils/axiosInstance";
import {useTranslation} from "react-i18next";

function CustomerFormComponent(props) {

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const createIntervention = useSelector(state => state.create);
    const {register, getValues, watch, setValue, formState: {errors, isValid, isDirty}} = useForm();
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const zipCode = watch("zipCode");

    useEffect(() => {
        setNextButtonState({
            disabled: !isValid,
            label: t('generic.next'),
            isPrimary: true,
            onNext: () => {
                dispatch(updateCustomer(getValues()));
                setStep("FINAL_VALID");
            }
        })
    }, [isDirty, isValid])

    useEffect(() => {
        document.getElementById('lastName').focus();
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {
                setStep("SELECT_REPAIRS")
            }
        });
    }, [])

    useEffect(() => {

        if (zipCode && zipCode.length === 5) {
            axiosInstance.get("/zipcode/"+zipCode).then(response => {
                if (response.data) {
                    setValue("city", response.data);
                }
            })
        }
    }, [zipCode, setValue]);

    return (
        <>
            <PageHeader title={t('page.intervention.customer-infos')}/>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field required={true}>
                        <label htmlFor="lastName">{t('generic.lastname')}</label>
                        <input fluid name="lastName" id={"lastName"}
                               defaultValue={createIntervention.customer?.lastName}
                               {...register('lastName', {required: true})}
                               placeholder={t('generic.lastname')}/>
                    </Form.Field>
                    <Form.Field required={true}>
                        <label htmlFor="firstName">{t('generic.firstname')}</label>
                        <input fluid name="firstName" id={"firstName"}
                               defaultValue={createIntervention.customer?.firstName}
                               {...register('firstName', {required: true})}
                               placeholder={t('generic.firstname')}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field required={false}>
                        <label htmlFor="address">{t('generic.address')}</label>
                        <input fluid name="address" defaultValue={createIntervention.customer?.address}
                               {...register('address', {required: false})}
                               placeholder={t('generic.address')}/>
                    </Form.Field>
                    <Form.Field required={true}>
                        <label htmlFor="zipCode">{t('generic.postal-code')}</label>
                        <input fluid name="zipCode" defaultValue={createIntervention.customer?.zipCode}
                               {...register('zipCode', {required: true})}
                               placeholder={t('generic.postal-code')}/>
                    </Form.Field>
                    <Form.Field required={false}>
                        <label htmlFor="city">{t('generic.city')}</label>
                        <input fluid name="city" id={"city"} defaultValue={createIntervention.customer?.city}
                               {...register('city', {required: false})}
                               placeholder={t('generic.city')}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field required={true}>
                        <label htmlFor="email">{t('generic.email')}</label>
                        <input fluid name="email" id={"email"} defaultValue={createIntervention.customer?.email}
                               {...register('email', {required: true})}
                               placeholder={t('generic.email')}/>
                    </Form.Field>
                    <Form.Field required={false}>
                        <label htmlFor="lastName">{t('generic.phone-number')}</label>
                        <input fluid name="phone" defaultValue={createIntervention.customer?.phone}
                               {...register('phone', {required: false})}
                               placeholder={t('generic.phone-number')}/>
                    </Form.Field>
                </Form.Group>
            </Form>
        </>
    );
}


export default CustomerFormComponent;

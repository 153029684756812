import {Button, Icon, Input, Menu, Modal, Pagination, Table,} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import {toastSuccess} from "../../reducers/Global/global.actions";
import {LOGIN_SUCCESS} from "../../reducers/User/user.types";
import {useTranslation} from "react-i18next";

function BrokerSelectionModal(props) {

    const {t} = useTranslation();

    const {openModal, setOpenModal} = props;
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [partners, setPartners] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [nbrPage, setNbrPage] = useState(1);
    const [search, setSearch] = useState("");

    const fetchPartners = () => {
        axiosInstance.get("/partners", {
            params: {
                page: activePage,
                size: 10,
                search: search
            }
        })
            .then((response) => {
                setPartners(response.data.content);
                setNbrPage(response.data.totalPages)
            })
    }

    useEffect(() => {
        if(openModal) {
            fetchPartners();
        }
    }, [activePage, search, openModal]);

    const handlePaginationChange = (e, pageInfo) => {
        setActivePage(pageInfo.activePage - 1);
    };

    function changeBroker(partner) {

        axiosInstance.get("/change-broker",{
            params: {
                brokerId: partner.id
            }
        }).then(response => {
            dispatch({type: LOGIN_SUCCESS, payload: response.data});
            toastSuccess(t('header.welcome') + " " +response.data.broker.name);
            setOpenModal(false);
            navigate("/")
        });
    }

    return (
        <Modal
            onClose={() => setOpenModal(false)}
            onOpen={() => setOpenModal(true)}
            open={openModal}
            size={"large"}
        >
            <Modal.Header>{t('header.change-partner')}</Modal.Header>
            <Modal.Content>

                <Menu borderless={true} text>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Input icon='search' value={search} placeholder={t('generic.search-placeholder')}
                                   onChange={(e, {name, value}) => {
                                       setSearch(value)
                                       setActivePage(0)
                                   }}
                            />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Table compact striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell> {t('generic.name')}</Table.HeaderCell>
                            <Table.HeaderCell> {t('generic.code')}</Table.HeaderCell>
                            <Table.HeaderCell> {t('generic.actions')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {partners.map((partner) => (
                            <Table.Row key={partner.id}>
                                <Table.Cell>{partner.id}</Table.Cell>
                                <Table.Cell>{partner.name}</Table.Cell>
                                <Table.Cell>{partner.code}</Table.Cell>
                                <Table.Cell>
                                    <Button primary onClick={() => changeBroker(partner)}>
                                        {t('generic.chose')}
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Pagination style={{marginTop: '8px'}}
                            activePage={activePage + 1}
                            onPageChange={handlePaginationChange}
                            totalPages={nbrPage}
                            defaultActivePage={0}
                />

            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => setOpenModal(false)}>
                    {t('generic.cancel')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}


export default BrokerSelectionModal;

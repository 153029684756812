import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Container,
    Form,
    Grid,
    Icon,
    Input,
    Label,
    Menu,
    Pagination,
    Popup,
    Table
} from "semantic-ui-react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import axiosInstance from "../utils/axiosInstance";
import Moment from "react-moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {rangeDateOfLastXDays, transformRangeDate} from "../utils/date-util";
import FileExportButton from "../components/FileExportButton/FileExportButton";
import FastRangeDateSelection from "../components/FastRangeDateSelection/FastRangeDateSelection";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


const FollowupPage = () => {

    const {t} = useTranslation();

    const [search, setSearch] = useState("");
    const [fetchSize, setFetchSize] = useState(25);
    const [sortColumn, setSortColumn] = useState("creationDate");
    const [sortDirection, setSortDirection] = useState("descending");
    const [activePage, setActivePage] = useState(0);
    const [nbrPage, setNbrPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [interventions, setInterventions] = useState([]);
    const [rangeDate, setRangeDate] = useState(rangeDateOfLastXDays(90));

    const [searchFilters, setSearchFilters] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searchOpenPopupColumn, setSearchOpenPopupColumn] = useState(null);
    const navigate = useNavigate();


    const onChange = (event, data) => {
        if (!data.value || (data.value && data.value.length === 0))
            setRangeDate(null);
        if (data.value && data.value.length === 2)
            setRangeDate(data.value ? transformRangeDate(data.value) : null);
    }

    const followup = () => {
        axiosInstance.post("/followup", {
            searchFilters: searchFilters,
            fromDate: rangeDate && rangeDate.length > 0 ? rangeDate[0] : null,
            toDate: rangeDate && rangeDate.length > 0 ? rangeDate[1] : null,
        }, {
            params: {
                page: activePage,
                size: fetchSize,
                search: search,
                sort: sortColumn ? (sortColumn + (sortDirection === 'ascending' ? ',asc' : ',desc')) : null
            }
        })
            .then((response) => {
                setInterventions(response.data.content);
                setNbrPage(response.data.totalPages);
                setTotalElements(response.data.totalElements);
            })
    }

    useEffect(() => {
        followup();
    }, [search, activePage, fetchSize, sortColumn, sortDirection, rangeDate, searchFilters]);

    function sort(column) {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(column);
            setSortDirection('ascending');
        }
    }

    function addFilter(column, value, columnName) {
        setSearchValue(value);
        setActivePage(0);
        if (searchFiltersHasColumn(column))
            if (!value)
                setSearchFilters(searchFilters.filter(searchFilter => searchFilter.column !== column));
            else
                setSearchFilters([...searchFilters.filter(searchFilter => searchFilter.column !== column), {
                    column: column,
                    value: value,
                    columnName: columnName
                }]);
        else
            setSearchFilters([...searchFilters, {column: column, value: value, columnName: columnName}]);
    }

    function searchFiltersHasColumn(column) {
        return searchFilters.find(searchFilter => searchFilter.column === column);
    }

    function getSearchFilterValue(column) {
        return searchFiltersHasColumn(column) ? searchFiltersHasColumn(column).value : "";
    }

    const handlePaginationChange = (e, pageInfo) => {
        setActivePage(pageInfo.activePage - 1);
    };

    const headerCell = (columnId, columnName) => <Table.HeaderCell
        sorted={sortColumn === columnId ? sortDirection : null}
        style={{position: "relative"}}
    >
        <Container textAlign="left">{columnName}</Container>
        <Container
            textAlign="right"
            style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                fontSize: "10px",
            }}
        >

            {["imei", "brandName", "modelName", "interventionId", "claimReference", "customerFirstName", "customerLastName"].includes(columnId) &&
                <Popup
                    on='click'
                    onOpen={() => {
                        setSearchValue(getSearchFilterValue(columnId))
                        setSearchOpenPopupColumn(columnId)
                    }}
                    open={searchOpenPopupColumn === columnId}
                    onClose={() => setSearchOpenPopupColumn(null)}
                    trigger={
                        <Icon
                            style={{cursor: 'pointer'}}
                            name={searchFiltersHasColumn(columnId) ? "filter" : "search"}
                            color={searchFiltersHasColumn(columnId) ? "teal" : "black"}
                        />
                    }>
                    <Form>
                        <Form.Field>
                            <label>{columnName}</label>
                            <Input
                                action={<Button type='submit' disabled={!searchValue}
                                                onClick={() => {
                                                    addFilter(columnId, searchValue, columnName)
                                                    setSearchOpenPopupColumn(null)
                                                }}>{t('generic.apply')}</Button>}
                                icon='search'
                                onChange={(e, {value}) => setSearchValue(value)}
                                iconPosition='left'
                                placeholder={t('generic.search-placeholder')}
                                autoFocus
                                defaultValue={searchFiltersHasColumn(columnId) ? searchFiltersHasColumn(columnId).value : ""}
                                value={searchValue}
                            />
                            {searchFiltersHasColumn(columnId) && <a onClick={() => {
                                addFilter(columnId, "", columnName)
                                searchOpenPopupColumn === columnId && setSearchOpenPopupColumn(null)
                            }}>{t('generic.delete')}</a>}
                        </Form.Field>
                    </Form>

                </Popup>}


            {["status"].includes(columnId) &&
                <Popup
                    on='click'
                    onOpen={() => {
                        setSearchValue(getSearchFilterValue(columnId))
                        setSearchOpenPopupColumn(columnId)
                    }}
                    open={searchOpenPopupColumn === columnId}
                    onClose={() => setSearchOpenPopupColumn(null)}
                    trigger={
                        <Icon
                            style={{cursor: 'pointer'}}
                            name={searchFiltersHasColumn(columnId) ? "filter" : "search"}
                            color={searchFiltersHasColumn(columnId) ? "teal" : "black"}
                        />
                    }>
                    <Form>
                        <Form.Field>
                            <label>{columnName}</label>
                            <Form.Select fluid options={[
                                {key: '-1', value: '-1', text: t('status.-1')},
                                {key: '0', value: '0', text: t('status.0')},
                                {key: '1', value: '1', text: t('status.1')},
                                {key: '2', value: '2', text: t('status.2')},
                                {key: '21', value: '21', text: t('status.21')},
                                {key: '264', value: '264', text: t('status.3')},
                                {key: '-403', value: '-403', text: t('status.-403')},
                            ]} placeholder={t('page.followup.status')} onChange={(e, {name, value}) => {
                                addFilter(columnId, value, columnName)
                                setSearchOpenPopupColumn(null)
                            }} defaultValue={searchFiltersHasColumn(columnId) ? searchFiltersHasColumn(columnId).value : ""}/>
                            {searchFiltersHasColumn(columnId) && <a onClick={() => {
                                addFilter(columnId, "", columnName)
                                searchOpenPopupColumn === columnId && setSearchOpenPopupColumn(null)
                            }}>{t('generic.delete')}</a>}
                        </Form.Field>
                    </Form>

                </Popup>}

            <Icon
                style={{cursor: 'pointer'}}
                name={sortColumn === columnId ? (sortDirection === "ascending" ? "sort ascending" : "sort down") : "sort"}
                onClick={() => sort(columnId)}
                color={sortColumn === columnId ? "teal" : "black"}
            />
        </Container>

    </Table.HeaderCell>;

    return (
        <>

            <Menu borderless={true} text>
                <Menu.Menu>
                    <Menu.Item style={{paddingLeft: '0px'}}>
                        <SemanticDatepicker value={rangeDate} onChange={onChange} type="range" format={'DD/MM/YYYY'}
                                            locale={"fr-FR"}/> <FastRangeDateSelection
                        trigger={<Icon style={{marginLeft: '3px', cursor: 'pointer'}} circular name='lightning'/>}
                        setRangeDate={setRangeDate}/>
                    </Menu.Item>
                </Menu.Menu>

                <Menu.Menu position='right'>
                    <Menu.Item>
                        <FileExportButton url="/followup/excel" nameFile="export"
                                          datas={{
                                              searchFilters: searchFilters,
                                              fromDate: rangeDate && rangeDate.length > 0 ? rangeDate[0] : null,
                                              toDate: rangeDate && rangeDate.length > 0 ? rangeDate[1] : null,
                                          }}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            {searchFilters.length > 0 && <b>{t('generic.filters-applied')} : </b>}
            {searchFilters.map((filter, index) => <Label as='a'>
                {filter.columnName}
                <Label.Detail>{filter.column == 'status' ? t("status." + filter.value): filter.value}</Label.Detail>
                <Icon onClick={() => addFilter(filter.column, "")} name='cancel'
                      style={{marginLeft: '10px'}}/>
            </Label>)
            }

            <Table celled fixed stackable>
                <Table.Header>
                    <Table.Row>
                        {headerCell("status", t('page.followup.status'))}
                        {headerCell("interventionId", t('generic.intervention-id'))}
                        {headerCell("claimReference", t('generic.external-id'))}
                        {headerCell("creationDate", t('page.followup.creation-date'))}
                        {headerCell("receptionDate", t('page.followup.reception-date'))}
                        {headerCell("productType", t('generic.type'))}
                        {headerCell("brandName", t('generic.brand'))}
                        {headerCell("modelName", t('generic.model'))}
                        {headerCell("imei", "IMEI")}
                        {headerCell("customerFirstName", t('generic.firstname'))}
                        {headerCell("customerLastName", t('generic.lastname'))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {interventions.map((intervention) => (
                        <Table.Row key={intervention.interventionId} positive={intervention.status === '2'}
                                   negative={intervention.status === '21'}>
                            <Table.Cell>
                                {t("status." + intervention.status)}
                            </Table.Cell>
                            <Table.Cell textAlign={"center"}>
                                <b><a
                                    onClick={() => navigate("/followup/" + intervention.interventionId)}><u>{intervention.interventionId}</u>&nbsp;
                                    <Icon size={"small"} name='linkify'/></a></b>
                            </Table.Cell>
                            <Table.Cell>
                                <Popup
                                    trigger={<span>{intervention.claimReference}</span>}
                                    content={intervention.claimReference}
                                    basic
                                />
                            </Table.Cell>
                            <Table.Cell><Moment format="DD/MM/YYYY"
                                                locale="fr">{intervention.creationDate}</Moment></Table.Cell>
                            <Table.Cell>{intervention.receptionDate && <Moment format="DD/MM/YYYY"
                                                                               locale="fr">{intervention.receptionDate}</Moment>}</Table.Cell>
                            <Table.Cell>{t('product.type.' + intervention.productType)}</Table.Cell>
                            <Table.Cell>{intervention.brandName}</Table.Cell>
                            <Table.Cell>{intervention.modelName}</Table.Cell>
                            <Table.Cell>
                                <Popup
                                    trigger={<span>{intervention.imei}</span>}
                                    content={intervention.imei}
                                    basic
                                />
                            </Table.Cell>
                            <Table.Cell>{intervention.customerFirstName}</Table.Cell>
                            <Table.Cell>{intervention.customerLastName}</Table.Cell>
                        </Table.Row>
                    ))}
                    {interventions.length === 0 && <Table.Row>
                        <Table.Cell colSpan={10}>{t('page.followup.no-repairs')} {(rangeDate || searchFilters) &&
                            <a onClick={() => {
                                setRangeDate([]);
                                setSearchFilters([]);
                            }}>{t('generic.delete-filters')}</a>}</Table.Cell>
                    </Table.Row>}
                </Table.Body>
            </Table>
            <Grid>
                <Grid.Column floated='left' width={5}>
                    {totalElements > 0 && <div style={{textAlign: 'left'}}>
                        {totalElements}{' '} {t('pagination.results')}
                    </div>}
                </Grid.Column>
                <Grid.Column floated='right' width={5}>
                    <div style={{textAlign: 'right'}}>
                        {t('pagination.display')}{' '}
                        <Dropdown upward floating inline value={fetchSize}
                                  onChange={(e, {name, value}) => setFetchSize(value)} options={[
                            {key: '25', value: 25, text: t('pagination.results-25')},
                            {key: '50', value: 50, text: t('pagination.results-50')},
                            {key: '100', value: 100, text: t('pagination.results-100')}]}/>{' '} {t('pagination.by-page')}
                    </div>
                </Grid.Column>
            </Grid>
            {totalElements > 0 && <Pagination
                        activePage={activePage + 1}
                        onPageChange={handlePaginationChange}
                        totalPages={nbrPage}
            />}

        </>
    );
};

export default FollowupPage;
